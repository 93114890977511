import $ from 'jquery'
import parallaxElement from 'parallax-element'

class Parallax {
    constructor(selectors) {
        $.each(selectors, (index, selector) => {
            $(selector).addClass('has-parallax')

            $(selector).parallaxElement({
                defaultSpeed: 0.06
            })
        })
    }
}

export default Parallax