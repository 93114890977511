import $ from 'jquery'

class MediaHeroSlider {
    constructor(el) {
        this.mediaSliderEl = $(el)
        this.mediaEl = this.mediaSliderEl.find('.c-media')

        setTimeout(() => {
            this.setInitialState()
        }, 200)

        this.mediaEl.on('mouseover', (e) => this.setActiveState(e))
    }

    setInitialState() {
        let initialEl = this.mediaSliderEl.find('.c-media').first()
        let inactiveEl = this.mediaEl.not(initialEl)

        initialEl.addClass('is-active')

        this.resetMediaState(inactiveEl)
    }

    setActiveState(event) {
        let currentEl = $(event.currentTarget)
        let inactiveEl = this.mediaEl.not(currentEl)

        currentEl.find('.c-slider').flickity('select', 0).flickity('unpausePlayer')
        currentEl.addClass('is-active')

        this.resetMediaState(inactiveEl)
    }

    resetMediaState(el) {
        el.removeClass('is-active')
        el.find('.c-slider').flickity('pausePlayer').flickity('select', 0)
    }
}

export default MediaHeroSlider
