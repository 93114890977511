import $ from "jquery";

class Offcanvas {
    constructor() {
        // Remove the menu__item--has-children class from second tier sub menus
        this.showSecondTierItems();

        this.elements = {
            menuItemWithChildren: $(".menu__item--has-children > a"),
            offcanvas: $(".c-offcanvas__inner"),
            closeButton: $(".c-page-header__navicon"),
            sectionItems: $(".c-section-navigation__item"),
            sectionMask: $(".c-section-navigation__mask"),
        };

        // Handle clicking a menu item with children
        this.elements.menuItemWithChildren.on("click", (e) =>
            this.showSubMenu(e)
        );

        // Handle hovering sectionItems children
        this.elements.sectionItems.on("mouseover hover", (e) =>
            this.showReleventIamge(e)
        );

        // hide section navigation on mask click
        this.elements.sectionMask.on("click", (e) => this.hideSectionNav());

        // Add a span around top level anchor content
        this.elements.menuItemWithChildren.find("> a").each(function () {
            $(this).wrapInner("<span/>");
        });

        // Reset the menu state when the offcanvas is closed
        this.elements.closeButton.on("click", (e) => this.resetMenuState(e));
    }

    showSubMenu(event) {
        event.preventDefault();
        let itemEl = $(event.currentTarget);

        this.elements.menuItemWithChildren
            .not(itemEl)
            .parent()
            .removeClass("is-active");
        itemEl.parent().toggleClass("is-active");
    }

    showReleventIamge(event, item) {
        event.preventDefault();
        let itemEl = $(event.currentTarget);
        let itemID = itemEl.attr("id");

        $(".c-section-navigation__image").css("opacity", "0");
        $(".c-section-navigation__image." + itemEl.attr("id")).css(
            "opacity",
            "1"
        );
    }

    resetMenuState(event) {
        setTimeout(() => {
            this.elements.offcanvas.scrollTop(0);
            this.elements.menuItemWithChildren.removeClass("is-active");
        }, 500);
    }

    hideSectionNav() {
        $("body").removeClass("section-navigation-is-active");
    }

    showSecondTierItems() {
        const subs = ".menu__item--has-children .menu__item--has-children";
        const links = $(".menu__item--has-children > a");
        $(subs).each(function (i) {
            $(this).removeClass("menu__item--has-children");
        });
    }
}

export default Offcanvas;
