import $ from 'jquery'

class BookButton {
    constructor() {
        this.options = window.cnModuleBookButton

        // Handle clicking a table booking button
        $('body').on('click', 'a[href="#book-table"]', this.openTableBooking.bind(this))
    }

    openTableBooking() {
        window.open(this.options.bookTableUrl, '_blank')
    }
}

export default BookButton