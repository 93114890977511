import $ from 'jquery'

class GridFilter {
    constructor(el) {
        this.elements = {
            base: $(el),
            filterContainer: $(el).find('.c-grid-filter__filter'),
            trigger: $(el).find('.c-grid-filter__filter__label')
        }

        this.elements.trigger.on('click', this.toggleFilters.bind(this))
    }

    toggleFilters() {
        this.elements.filterContainer.toggleClass('is-active')
    }
}

export default GridFilter
