import $ from 'jquery'

class ContentPostFilter {
    constructor() {
        $('.c-content-post-filter__select').on('change', function () {
            $(this).parents('form').submit()
        })
    }
}

export default ContentPostFilter