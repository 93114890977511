import $ from 'jquery'

class ScrollDirection {
    constructor() {
        this.lastScrollTop = 0

        window.addEventListener('scroll', this.updatePageClass, false)
    }

    updatePageClass() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop

        if (scrollTop > this.lastScrollTop) {
            $('body').removeClass('page-is-scrolling-up')
            $('body').addClass('page-is-scrolling-down')
        } else {
            $('body').removeClass('page-is-scrolling-down')
            $('body').addClass('page-is-scrolling-up')
        }

        if (scrollTop <= 0) {
            $('body').addClass('page-is-at-top')
        } else {
            $('body').removeClass('page-is-at-top')
        }

        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }
}

export default ScrollDirection