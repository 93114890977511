import $ from 'jquery'

class BookingOption {
    constructor() {
        this.closeButtonEl = $('.js-booking-close')
        this.backButtonEl = $('.c-booking-option__form .c-button.no-variation')
        this.optionEl = $('.js-booking-option')
        this.optionTriggerEl = this.optionEl.find('.js-booking-option-trigger')

        if ($(window).width() > 768) {
            this.expandFirstOption()
        }

        // Handle clicking an option trigger
        this.optionTriggerEl.on('click', (e) => this.hideOtherOptions(e))

        // Handle clicking the close button
        this.closeButtonEl.on('click', () => this.resetState())
        this.backButtonEl.on('click', () => this.backtoForm())
    }

    expandFirstOption() {
        this.optionEl.first().addClass('is-active')
    }

    hideOtherOptions(event) {
        let triggerEl = $(event.currentTarget)
        let currentOptionEl = triggerEl.parent('.js-booking-option')

        this.optionEl.not(currentOptionEl).removeClass('is-active')
        currentOptionEl.addClass('is-active')
        $('body').addClass('booking-option-is-active')
    }

    resetState() {
        setTimeout(() => {
            // this.optionEl.removeClass('is-active')
            $('body').removeClass('booking-option-is-active')
        }, 500)
    }

    backtoForm() {
        setTimeout(() => {
            this.optionEl.removeClass('is-active')
            $('body').toggleClass('booking-option-is-active')
        }, 100)
    }
}

export default BookingOption