import $ from 'jquery'

class WineList {
    constructor() {
        this.selectEl = $('.c-wine-list-filter__select')

        this.selectEl.on('change', this.setActiveSelectClass)

        this.setActiveSelectClass()
    }

    setActiveSelectClass(event) {
        let selectEl = this.selectEl

        if (typeof event !== 'undefined') {
            selectEl = $(event.currentTarget)
        }

        selectEl.each(function () {
            let currentValue = $(this).val()

            if (currentValue === '') {
                $(this).addClass('has-default-value')
            } else {
                $(this).removeClass('has-default-value')
            }
        })
    }
}

export default WineList