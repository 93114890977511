import $ from 'jquery'

class Form {
    constructor() {
        let formEl = $('.c-form')
        let inputEl = formEl.find('input, textarea')

        inputEl.on('keyup', this.togglePlaceholder)
    }

    togglePlaceholder(event) {
        let inputEl = $(event.currentTarget)
        let fieldEl = inputEl.parents('.gfield')

        if (inputEl.val() !== '') {
            fieldEl.addClass('has-value')
        } else {
            fieldEl.removeClass('has-value')
        }
    }
}

export default Form