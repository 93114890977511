import $ from 'jquery'

class Splash {
    constructor() {
        this.bodyEl = $('body')
        this.closeButton = $('.c-splash__close')
        this.sliderEl = $('.c-slider--media-hero .c-media').first().find('.c-slider')

        if (this.bodyEl.hasClass('body--home')) {
            this.checkForCookie()
        } else {
            this.bodyEl.addClass('splash-has-shown')
        }

        $(window).on('cnVideoEnded', (e) => this.hideSplash())

        this.closeButton.on('click', (e) => this.hideSplash())
    }

    checkForCookie() {
        if (localStorage.getItem('vySplash')) {
            this.bodyEl.addClass('splash-has-shown')
        } else {
            this.showSplash()
        }
    }

    showSplash() {
        this.bodyEl.addClass('splash-is-visible')
        this.pauseSliders()
        localStorage.setItem('vySplash', true)
    }

    hideSplash() {
        this.bodyEl.addClass('splash-has-shown')
        this.bodyEl.removeClass('splash-is-visible')

        localStorage.setItem('vySplash', true)

        this.resumeSliders()
    }

    pauseSliders() {
        setTimeout(() => {
            this.sliderEl.flickity('pausePlayer')
        }, 500)
    }

    resumeSliders() {
        this.sliderEl.flickity('unpausePlayer')
    }
}

export default Splash