import $ from 'jquery'

class PageTransition {
    constructor() {
        this.window = $(window)
        this.body = $('body')
        this.pageMaskEl = $('.c-page-mask')

        this.hidePageMask()

        window.addEventListener("beforeunload", () => {
            const isMailTo = document.activeElement.protocol === "mailto:";
            const isTel = document.activeElement.protocol === "tel:";

            if (!isTel || !isMailTo) {
                this.handlePageExit.bind(this)
            }
        });
    }

    hidePageMask() {
        this.body.addClass('page-mask-has-shown')
    }

    handlePageExit() {
        this.body.addClass('page-is-changing')
        this.body.removeClass('page-mask-has-shown')
    }

    handlePageShow(event) {
        if (event.originalEvent.persisted) {
            window.location.reload()
        }
    }
}

export default PageTransition